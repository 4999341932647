import React from 'react';

import {
  Modal,
  Typography,
  Button,
  Box,
  Grid,
  useMediaQuery,
  CircularProgress,
  ButtonGroup,
  Rating,
} from '@mui/material';

import {
  Movies as MovieIcon,
  Favorite,
  Theaters,
  Language,
  PlusOne,
  FavoriteBorderOutlined,
  Remove,
  ArrowBack,
} from '@mui/icons-material';
import { Link, useParams, userParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import useStyles from './styles';
import { useGetMovieQuery } from '../../services/TMDB';

const MovieInformation = () => {
  const classes = useStyles();

  const { id } = useParams();
  const { data, isFetching, error } = useGetMovieQuery(id);

  if (isFetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop="100px"
      >
        <CircularProgress size="8rem" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop="100px"
      >
        <Link to="/">Something is wrong!</Link>
      </Box>
    );
  }

  console.log(data);

  return (
    <Grid className={classes.containerSpaceAround}>
      <Grid item sm={12} lg={4}>
        <img
          className={classes.poster}
          src={`https://image.tmdb.org/t/p/w500/${data?.poster_path}`}
        />
      </Grid>
    </Grid>
  );
};

export default MovieInformation;
