import { createSlice } from '@reduxjs/toolkit';

export const genreOrCategory = createSlice({
  name: 'genereOrCategory',
  initialState: {
    genereIdOrCategoryName: '',
    page: 1,
    searchQuery: '',
  },
  reducers: {
    selectGenreOrCategory: (state, action) => {
      // console.log(action.payload);
      state.genereIdOrCategoryName = action.payload;
    },
    searchMovie: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});

export default genreOrCategory.reducer;

export const { selectGenreOrCategory, searchMovie } = genreOrCategory.actions;
