import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  containerSpaceAround: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '70px !important',
    margin: '10px 0 !important',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  },
  poster: {
    borderRadius: '20px',
    boxShadow: '0.5em 1em 1em rgb(64,64,70)',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '80%',
      height: '350px',
      marginBottom: '30px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      width: '50%',
      height: '350px',
    },
  },
}));

// export default makeStyles((theme) => {
//     profile: {
//       marginTop: '50px',
//     },
//   });
