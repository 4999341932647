import React from 'react';
import useStyles from './styles';
import { Typography, Button, Box } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { userSelector } from '../../features/auth';

const Profile = () => {
  const classes = useStyles();
  const { user } = useSelector(userSelector);

  const favouriteMovie = [];

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" marginTop="50px">
        <Typography variant="h4" gutterBottom>
          {user.username}
        </Typography>
        <Button color="inherit" onClick={logout}>
          Logout &nbsp; <ExitToApp />
        </Button>
      </Box>
      {!favouriteMovie.length ? (
        <Typography variant="h5">
          Add movies to favourites to see them here!!
        </Typography>
      ) : (
        <Box>FAVOURITE MOVIES</Box>
      )}
    </Box>
  );
};

export default Profile;

{
  /* <div className={classes.profile}>{user.username}</div>; */
}
